import React from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import About from '../About/Components/About';

export default function AboutUs() {
    return (
        <div>
            <Header/>
            <About/>
            <Footer/>
        </div>
    )
}
