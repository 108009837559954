import React from 'react'
import slide1 from '../../../../dist/images/slide1.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Banner() {
    var settings = {
        dots: true,
        arrows:false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
      };
    return (
        <div>
            <div className="banner-section">
                <div className="slider-wrap pt-lg-5">
                    <Slider {...settings}>
                        <div>
                            <img className="slide-img img-fluid" src={slide1} alt="Banner Slide"/>        
                        </div>
                        <div>
                            <img className="slide-img img-fluid" src={slide1} alt="Banner Slide"/>        
                        </div>
                        <div>
                            <img className="slide-img img-fluid" src={slide1} alt="Banner Slide"/>        
                        </div>
                    </Slider>
                </div>
                <div className="banner-right-content text-center pt-lg-5 mt-lg-5 mt-md-3">
                    <h1 className="banner-title mt-4 mt-md-5">Do Fast Delivery</h1>
                    <div className="banner-inp-wrapper">
                        <input className="banner-search-inp"/>
                        <button type="submit" className="banner-submit-btn">Track Parcel</button>
                    </div>
                    <p className="banner-txt">With us, you may get your courier delivered is the safest way possible!</p>
                </div>
            </div>
        </div>
    )
}
