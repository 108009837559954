import React from 'react'
import {Link} from 'react-router-dom'
import {Form,Row,Col,Button} from 'react-bootstrap'

export default function Delivery() {
    return (
        <div>
        <section id="delivery" className="py-3 py-md-5">
            <div className="container my-3 my-md-5">
                <div className="row counter-row">
                    <div className="col-md-3 col-sm-6 col-6">
                            <div className="counter-item text-center">
                                <h2>25700+</h2>
                                <span>Parcel Deliverd</span>
                            </div>
                    </div>
                        <div className="col-md-3 col-sm-6 col-6">
                            <div className="counter-item text-center">
                                <h2>64</h2>
                                <span>Districts Coverage</span>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-6">
                            <div className="counter-item text-center">
                                <h2>500+</h2>
                                <span>Delivery Agent</span>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-6">
                            <div className="counter-item text-center">
                                <h2>8000+ </h2>
                                <span>Registered Marchants</span>
                            </div>
                        </div>
                </div>
            </div>
        </section>
        </div>
    )
}
