import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import InfoBgImg from '../../../dist/images/info-bg.jpg';
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

export default function CompanyInfo() {

    const [countries, setCountries] = useState([]);
    const [countryid, setCountryID] = useState(0);
    const [stateid, setStateID] = useState(23);
    console.log(countryid);
    useEffect(() => {
            const getCountriesData = async () => {
            const response = await fetch("http://cwc.i2hostingsolution.net/apps/shipments/ajaxed-get-country")
            const data = await response.json();
            return data;
    
        };
    
        getCountriesData().then((data) => {
            const countries = data.map((country) => (
                {
                    name: country.name,
                    value: country.id
                }
            ));
            setCountries(countries);
        });
     }, []);    

     const [districts , setdistricts] = useState([]);
     useEffect(() => {
         const getdistrictsData = async () => {
             const response = await fetch("http://cwc.i2hostingsolution.net/apps/shipments/ajaxed-get-states?country_id="+ countryid)
             const data = await response.json();
             return data;
     
         };
     
         getdistrictsData().then((data) => {
             const districts = data.map((district) => (
                 {
                     name: district.name,
                     value: district.id
                 }
             ));
             setdistricts(districts);
         });
      }, [countryid]);     

      const [areas , setareas] = useState([]);
      useEffect(() => {
          const getareasData = async () => {
              const response = await fetch("http://cwc.i2hostingsolution.net/apps/shipments/ajaxed-get-areas?state_id="+ stateid)
              const data = await response.json();
              return data;
      
          };
      
          getareasData().then((data) => {
              const areas = data.map((area) => (
                  {
                      name: area.name,
                      value: area.id
                  }
              ));
              setareas(areas);
          });
       }, [stateid]);     
 
    return (
        <div>
            <Header/>
            <section id="company-info" className="my-3 my-md-5">
                <div className="container-fluid  my-3 px-3 px-md-5">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="company-form">
                                <h2 className="section-title">Company Info</h2>
                                <Form className="form-wrap mt-3 mt-md-5">
                                    <div className="formInput">
                                    <Row>
                                            <Form.Group as={Col} md={6} className="mb-md-4 mb-2" controlId="formGridCompanyName">
                                                <Form.Label>Company Name</Form.Label>
                                                <Form.Control type="text"  className="custom-form-control"/>
                                            </Form.Group>
                                            
                                            <Form.Group as={Col} md={6} className="mb-md-4 mb-2" controlId="formGridCompanyName">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text"  className="custom-form-control"/>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} as={Col} md={6} className="mb-md-3 mb-2" controlId="formGridCity">
                                                <Form.Label>Mobile</Form.Label>
                                                <Form.Control type="number" className="custom-form-control"/>
                                            </Form.Group>
                                            <Form.Group as={Col} md={6} className="mb-md-4 mb-2" controlId="formGridSiteUrl">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="text" className="custom-form-control"/>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md={6} className="mb-md-4 mb-2" controlId="formGridCompanyName">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="password"  className="custom-form-control"/>
                                            </Form.Group>
                                            <Form.Group as={Col} md={6} className="mb-md-4 mb-2" controlId="formGridSiteUrl">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control type="password" className="custom-form-control"/>
                                            </Form.Group>
                                        </Row>

                                        <Row>
                                            <Form.Group as={Col} md={6} className="mb-md-4 mb-2" controlId="formGridSiteUrl">
                                                <Form.Label>Site URL(Facebook, Web)</Form.Label>
                                                <Form.Control type="text" className="custom-form-control"/>
                                            </Form.Group>

                                            <Form.Group as={Col} as={Col} md={6} className="mb-md-3 mb-2" controlId="formGridDivision">
                                                <Form.Label>Division</Form.Label>
                                                <Form.Select aria-label="Default select example" className="custom-form-control" onChange={e => setCountryID(e.target.value)}>
                                                    <option>Select Division</option>
                                                        {countries.map(country => (
                                                            
                                                        <option value={country.value}> { country.name } </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} as={Col} md={6} className="mb-md-3 mb-2" controlId="formGridDistrict">
                                                <Form.Label>District</Form.Label>
                                                <Form.Select aria-label="Default select example" className="custom-form-control" onChange={e => setStateID(e.target.value)} >
                                                    <option>Select District</option>
                                                    {districts.map(district => (
                                                            
                                                            <option value={district.value}> { district.name } </option>
                                                            ))}
    
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group as={Col} as={Col} md={6} className="mb-md-3 mb-2" controlId="formGridArea">
                                                <Form.Label>Area</Form.Label>
                                                <Form.Select aria-label="Default select example" className="custom-form-control">
                                                    <option>Select Area</option>
                                                    {areas.map(area => (
                                                            
                                                            <option value={area.value}> { area.name } </option>
                                                            ))}
    
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                        <Form.Group as={Col} as={Col} md={12} className="mb-md-3 mb-2" controlId="formGridAddress">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control className="custom-form-control" as="textarea" rows={2} />
                                        </Form.Group>
                                        </Row>


                                        <Row>
                                            <Form.Group as={Col} as={Col} md={12} className="text-center mt-3">
                                                <Link to="/accounts-info" className="custom-btn" type="button">Next</Link>
                                            </Form.Group>
                                        </Row>
                                        
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="info-right-img-div">
                                <img className="info-right-img img-fluid" src={InfoBgImg} alt="Service"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}
